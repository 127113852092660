.footer {
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
}

.footer a {
  color: inherit;
  text-decoration: none;
}