.header {
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  height: 60px;
  display: flex;
  align-items: stretch;
  padding: 0 20px;
}

.header-title {
  flex-grow: 1;
  text-align: center;
  font-size: 1.5rem;
}

.header svg {
  max-height: 40px;
}