.sidebar-drawer {
  width: 320px;
  background-color: var(--sidebar-background-color);
  color: var(--sidebar-text-color);
}

.sidebar-drawer .MuiListItem-root {
  border-bottom: 1px solid var(--sidebar-border-color);
  padding: 16px 24px;
}

.sidebar-drawer .MuiCollapse-root {
  background-color: var(--sidebar-collapse-background-color);
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.sidebar-drawer .MuiListItemText-primary {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  letter-spacing: 0.5px;
}

.sidebar-drawer .MuiListItemIcon-root {
  min-width: 40px;
}

.sidebar-section {
  margin-bottom: 16px;
}

.sidebar-section-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.sidebar-drawer .MuiButton-root {
  margin-top: 16px;
  width: 100%;
}

.sidebar-drawer .MuiTextField-root {
  margin-bottom: 16px;
}

.sidebar-drawer .MuiFormControl-root {
  width: 100%;
  margin-bottom: 16px;
}