/* src/styles/ModalComponent.css */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--modal-background-color);
  color: var(--modal-text-color);
  border: 1px solid var(--text-color);
  box-shadow: 24px;
  padding: 16px;
  width: 420px;
  text-align: center;
}

.modal-logo {
  width: 100px;
  margin-bottom: 20px;
}

.modal-text {
  font-weight: bold;
  font-style: italic;
  padding-top: 10px;
}

.modal-description span {
  font-weight: bold;
  font-style: italic;
}

.modal-powered {
  font-size: 12px;
  font-style: italic;
  padding-top: 10px;
}

@media screen and (max-width: 420px) {
  .modal-box {
    width: 80%;
  }
}