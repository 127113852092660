:root {
  --primary-color: #7E97A6;
  --secondary-color: #B6F7C1;
  --accent-color: #63686E;
  
  --background-color: #F5F5F5;
  --surface-color: #FFFFFF;
  --text-color: #373640;
  
  --header-background-color: #7E97A6;
  --header-text-color: #FFFFFF;
  
  --footer-background-color: #F0F0F0;
  --footer-text-color: #63686E;
  
  --sidebar-background-color: #FFFFFF;
  --sidebar-text-color: #373640;
  --sidebar-border-color: #E0E0E0;
  --sidebar-collapse-background-color: #F5F5F5;
  
  --modal-background-color: #FFFFFF;
  --modal-text-color: #373640;
}

.dark-mode {
  --primary-color: #7E97A6;
  --secondary-color: #B6F7C1;
  --accent-color: #63686E;
  
  --background-color: #373640;
  --surface-color: #444750;
  --text-color: #F5F5F5;
  
  --header-background-color: #2A2C33;
  --header-text-color: #B6F7C1;
  
  --footer-background-color: #2A2C33;
  --footer-text-color: #7E97A6;
  
  --sidebar-background-color: #444750;
  --sidebar-text-color: #F5F5F5;
  --sidebar-border-color: #63686E;
  --sidebar-collapse-background-color: #373640;
  
  --modal-background-color: #444750;
  --modal-text-color: #F5F5F5;
}