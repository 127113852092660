@import "./styles/variables.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  height: 60px;
  flex-shrink: 0;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  display: flex;
  align-items: center;
  padding: 0 20px;
}

footer {
  height: 40px;
  flex-shrink: 0;
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  display: flex;
  align-items: center;
  padding: 0 20px;
  text-transform: lowercase;
}

.content-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.drawer {
  top: 60px;
  height: calc(100% - 100px);
  width: 400px;
  background-color: var(--sidebar-background-color);
  color: var(--sidebar-text-color);
  padding: 20px;
  text-transform: lowercase;
  border: 1px solid var(--sidebar-border-color);
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

#stage-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) {
  .canvas-container {
    max-width: 100%;
    max-height: 100%;
  }
}